<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" :text="loadingText"></loading>
    <div style="height: 30px; width: 100%;">累计成交：</div>
    <el-form ref="form"  label-width="100px;" style="height: 50px;" >
      <el-form-item label="累计成交笔数：" style="width:33%; float:left">
        {{report.totalOrders}}笔
      </el-form-item>
      <el-form-item label="累计成交金额：" style="width:33%; float:left;">
        {{report.totalAmount}}元
      </el-form-item>
      <el-form-item label="账户余额：" style="width:33%; float:left;">
        {{report.balance}}元
      </el-form-item>
    </el-form>
    <div style="border-bottom: 1px solid #ccc; height: 1px;width:100%;"></div>
    <div style="height: 30px;padding:10px 0px; text-align: left">收益明细：</div>
    <el-table id="tblList" border :data="records"   style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
      <el-table-column prop="courseName" label="课程名称" align="center" ></el-table-column>
      <el-table-column  label="收益金额" align="center"  >
        <template slot-scope="scope">
          {{scope.row.amount}}元
        </template>
      </el-table-column>
      <el-table-column prop="createdOn" label="创建日期" align="center" width="180"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import {request, UrlEnum} from "../../../../public/js/common-vue";
import Loading from "../../../components/Loading";
export default {
  name: "DistributionReport",
  data() {
    return {
      loading: false,
      loadingText:"",
      id:0,
      tableShow:false,
      report:{
        totalAmount: 0,
        totalOrders: 0
      },
      records:[],
    }
  },
  methods:{
    initData(){
      this.getReport();
      this.getRecords();
    },
    getReport(){
      this.loading = true;
      this.loadingText = "加载中";
      this.$http({
        method: 'GET',
        url: UrlEnum.DISTRIBUTION_INCOMEREPORT + "?promoterId=" + request('promoterId'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.report = res.data;
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getRecords(){
      this.loading = true;
      this.loadingText = "加载中";
      this.$http({
        method: 'GET',
        url: UrlEnum.DISTRIBUTION_INCOMERECORDS + "?page=1&size=10000&promoterId=" + request('promoterId'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.tableShow=true;
        console.log(res.data.list);
        this.records = res.data.list;
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
  components:{ Loading }
}
</script>

<style>
.select-main{
  height: 100%;
}
</style>
